import { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { AgentsService } from "../../services/agent.service";
import { Controller, FieldError, useForm } from "react-hook-form";
import { AlertService } from "../../services/alert.service";
import Header from "../../components/layout/header.component";
import Select from "react-select";
import TextArea from "../../components/forms/text-area.component";
import Footer from "../../components/layout/footer.component";
import useBgPages from "../../hooks/bg-pages.hook";
import Button from "../../components/forms/button.component";
import useRequest from "../../hooks/use-request.hook";
import InvalidFeedback from "../../components/forms/invalid-feedback.component";
import DateHour from "../../components/forms/date-hour.component";
import SecurityQuestion from "../../pages/agents/security-question.modal";
import Input from "../../components/forms/input.component";
import moment from "moment";
import useInputValidation from "../../hooks/input-validation.hook";

interface Option {
  label: string;
  value: string;
  children?: Option[];
}

interface Control {
  max_length: any;
  label: string;
  type: string;
  options?: string[];
  is_mandatory: boolean;
  group: string;
  show_when: {
    group: string;
    is: string;
  };
}

interface DataBAse {
  legend: string;
  max: number;
  controls: Control[];
}

type MyFieldErrors = {
  id: any;
  register: any;
  campaign: string;
  agent: string;
  level_1: string;
  level_2: string;
  level_3: string;
  action: string;
  time_to_reschedule: string;
  comments: string;
  forms: {
    [index: number]: {
      [ctrlIndex: number]: {
        [key: string]: FieldError | any;
      };
    };
  };
};
interface FormData {
  campaign: string;
  id: any;
  register: any;
  agent: string;
  level_1: string;
  level_2: string;
  level_3: string;
  action: string;
  time_to_reschedule: string;
  comments: string;
  forms: {
    [key: number]: { [key: number]: any };
  };
}

const OpenRoutePage = () => {
  useBgPages();
  const {
    handleSpecialCharacters,
    handleSpecialCharactersDoubleSpaces,
    handleNoDoubleSpaces,
  } = useInputValidation();
  const { call } = useRequest();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const customer_id = searchParams.get("customer_id");
  const ucontact_id = searchParams.get("ucontact_id");
  const token = searchParams.get("token");
  const [number, setNumber] = useState("Sin información");
  const [userAgent, setUserAgent] = useState("Sin información");
  const [nameAgent, setNameAgent] = useState("Sin información");
  const [idAgent, setIdAgent] = useState("Sin información");
  const [idCampaigns, setIdCampaigns] = useState("");
  const [folio, setFolio] = useState("");
  const [rows, setRows] = useState([]);
  const [tipifications, setTipifications] = useState<Option[]>([]);
  const [dataBase, setDataBase] = useState<DataBAse[]>([]);
  const [questionSuccess, setQuestionSuccess] = useState(false);
  const [curpError, setCurpError] = useState("");
  const [dateError, setDateError] = useState("");
  const [dataRules, setDataRules] = useState([]);
  const dateHourFormatted = moment().format("DDMMYYHH");
  const [postMessageData, setPostMessageData] = useState<string>("");
  const [createdForms, setCreatedForms] = useState<{ [key: string]: any[] }>(
    {},
  );
  const [statusInputs, setStatusInputs] = useState<
    { group: string; is: string }[]
  >([]);

  const [showModal, setShowModal] = useState({
    show: false,
    data: null,
  });

  const actions = [
    { value: "RESPOOL", label: "Llamar de inmediato" },
    { value: "RESCHEDULE", label: "Reagendar llamada" },
    { value: "NO_ACTION", label: "Sin acción" },
    { value: "SALE", label: "Contacto efectivo con titular" },
  ];

  const defaultValues: FormData = {
    id: "",
    register: customer_id,
    campaign: "",
    agent: "",
    level_1: "",
    level_2: "",
    level_3: "",
    action: "",
    time_to_reschedule: "",
    comments: "",
    forms: [],
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isValid, isSubmitting },
  } = useForm<MyFieldErrors>({
    mode: "onChange",
    defaultValues,
  });

  const level2 = watch("level_1");
  const level3 = watch("level_2");
  const actionsV = watch("action");
  const allFormsEmpty = Object.values(createdForms).every(
    (form) => form.length === 0,
  );

  const getPatternForType = (type: string) => {
    switch (type) {
      case "text":
        return /.*/;
      case "number":
        return /^\d+(\.\d{1,2})?$/;
      case "cp":
        return /^\d{5}$/;
      case "phone_number":
        return /^\d{10}$/;
      case "email":
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      case "card":
        return /^\d{15,16}$/;
      case "rfc":
        return /^[A-Z&]{4}\d{6}[A-Z0-9]{0,3}$/;
      case "curp":
        return /^[A-Z]{4}\d{6}[HM][A-Z0-9]{7}$/;
      case "fecha_nacimiento":
        return /^([0-2][0-9]|(3)[0-1])\/(0[1-9]|1[0-2])\/([0-9]{4})$/;
      case "no_exterior":
      case "no_interior":
        return /^[a-zA-Z0-9]{1,5}$/;
      default:
        return /.*/;
    }
  };

  const getMessageForType = (type: string) => {
    switch (type) {
      case "text":
        return "El formato debe ser texto.";
      case "number":
        return "El formato debe ser un número, ejemplo: 123.45 ó 63345";
      case "cp":
        return "El formato debe ser un código postal de 5 dígitos, ejemplo: 52775";
      case "phone_number":
        return "El formato debe ser un número de teléfono de 10 dígitos, ejemplo: 5500550055";
      case "email":
        return "El formato debe ser un correo electrónico válido, ejemplo: ejemplo@dominio.com";
      case "rfc":
        return "El RFC puede ser con homoclave XXXX9609128S5 o sin homoclave XXXX960912";
      case "curp":
        return "El CURP debe ser válido y la persona debe ser mayor de 18 años.";
      case "fecha_nacimiento":
        return "La fecha de nacimiento es incorrecta este es el formato correcto dd/mm/aaaa.";
      case "no_exterior":
      case "no_interior":
        return "Este campo solo debe contener un máximo de 5 caracteres  y no debe de contener caracteres especiales.";

      default:
        return "";
    }
  };

  const validateCurp = (curp: string) => {
    const añoNacimiento = parseInt(curp.slice(4, 6), 10);
    const mesNacimiento = parseInt(curp.slice(6, 8), 10);
    const diaNacimiento = parseInt(curp.slice(8, 10), 10);

    const añoCompleto =
      añoNacimiento >= 0 && añoNacimiento <= 22
        ? 2000 + añoNacimiento
        : 1900 + añoNacimiento;

    const fechaNacimiento = new Date(
      añoCompleto,
      mesNacimiento - 1,
      diaNacimiento,
    );
    const fechaActual = new Date();
    let diferencia = fechaActual.getFullYear() - fechaNacimiento.getFullYear();
    const mesActual = fechaActual.getMonth();
    const diaActual = fechaActual.getDate();

    if (
      mesActual < mesNacimiento ||
      (mesActual === mesNacimiento && diaActual < diaNacimiento)
    ) {
      diferencia--;
    }

    const errorText =
      "El CURP no tiene un formato válido, deben ser 18 caracteres XXXX820302HPLNLR09 y  debe ser igual o mayor a 18 años de edad";

    if (diferencia < 18) {
      return {
        valid: false,
        message: errorText,
      };
    }

    return {
      valid: true,
      message: errorText,
    };
  };

  const validateDateOfBirth = (date: string) => {
    const [day, month, year] = date.split("/").map(Number);
    const birthDate = new Date(year, month - 1, day);
    const currentDate = new Date();
    const age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDifference = currentDate.getMonth() - birthDate.getMonth();
    const dayDifference = currentDate.getDate() - birthDate.getDate();
    const errorText =
      "La fecha de nacimiento no tiene un formato válido dd/mm/yyyy debe ser igual o mayor a 18 años de edad";
    if (
      age < 18 ||
      (age === 18 &&
        (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)))
    ) {
      return {
        valid: false,
        message: errorText,
      };
    }
    return {
      valid: true,
      message: errorText,
    };
  };

  const maskNumber = (num: any) => {
    if (num.length > 4) {
      return num.replace(/\d(?=\d{4})/g, "*");
    }
    return num;
  };

  const generateUniqueId = (length = 8) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length),
      );
    }
    return result;
  };

  const handleCreateForm = (legend: string) => {
    const formConfig = dataBase.find((item) => item.legend === legend);

    if (formConfig && createdForms[legend].length < formConfig.max) {
      const uniqueId = generateUniqueId(8);
      setCreatedForms((prevForms) => ({
        ...prevForms,
        [legend]: [
          ...prevForms[legend],
          {
            id: uniqueId,
          },
        ],
      }));
    } else {
      AlertService.toastWarning(
        `¡Atención!, ya no puedes crear más formularios de tipo "${legend}", ya has alcanzado el máximo permitido.`,
      );
    }
  };

  const handleRemoveGroup = (
    legend: string,
    index: number,
    controls: Control[],
  ) => {
    setCreatedForms((prevForms) => ({
      ...prevForms,
      [legend]: prevForms[legend].filter((_, i) => i !== index),
    }));
    const newStatusInputs = [...statusInputs];
    controls.forEach((control) => {
      if (control.group) {
        const groupIndex = newStatusInputs.findIndex(
          (status) => status.group === control.group,
        );
        if (groupIndex !== -1) {
          newStatusInputs.splice(groupIndex, 1);
        }
      }
    });
    setStatusInputs(newStatusInputs);
  };

  const handleSelectChange = (newValue: string, group: string) => {
    setStatusInputs((prevState) => {
      const existingGroupIndex = prevState.findIndex(
        (item) => item.group === group,
      );

      if (existingGroupIndex !== -1) {
        return prevState.map((item, index) =>
          index === existingGroupIndex ? { ...item, is: newValue } : item,
        );
      } else {
        return [...prevState, { group, is: newValue }];
      }
    });
  };

  const shouldShowInput = (controls: any) => {
    if (statusInputs.length === 0) return false;
    return statusInputs.some(
      (item) =>
        item.group === controls.show_when.group &&
        item.is === controls.show_when.is,
    );
  };

  const handleShowModal = async (refresh: boolean, question: boolean) => {
    if (refresh) {
      await getCustomer();
    }
    setShowModal({ show: false, data: null });
    if (!question) {
      reset(defaultValues);
      setQuestionSuccess(false);
    }
  };

  const handleActionChange = (selectedOption: any) => {
    const selectedValue = selectedOption ? selectedOption.value : null;

    if (selectedValue === "SALE") {
      const securityQuestionRow = dataRules.find(
        (row: { is_security_question: boolean }) =>
          row.is_security_question === true,
      );

      if (securityQuestionRow) {
        setShowModal({
          show: true,
          data: securityQuestionRow,
        });
      }
    }
  };

  const getChildrenOptions = (options: any, value: any) =>
    options.find((option: any) => option.value === value)?.children || [];

  const [optionsLevel2, hasChildrenLevel2] = useMemo(() => {
    const children = getChildrenOptions(tipifications, level2);
    return [
      children.map((child: any) => ({
        value: child.label,
        label: child.label,
      })),
      children.length > 0,
    ];
  }, [level2, tipifications]);

  const [optionsLevel3, hasChildrenLevel3] = useMemo(() => {
    const childrenLevel2 = getChildrenOptions(tipifications, level2);
    const children = getChildrenOptions(childrenLevel2, level3);
    return [
      children.map((child: any) => ({
        value: child.label,
        label: child.label,
      })),
      children.length > 0,
    ];
  }, [level2, level3, tipifications]);

  const getCustomer = async () => {
    if (customer_id && ucontact_id && token) {
      try {
        const response = await call(() =>
          AgentsService.getCustomer(customer_id, ucontact_id, token),
        );

        const { customer, campaign, agent } = response;

        if (!customer || !campaign || !agent) {
          const missingData = [];
          if (!customer) missingData.push("customer");
          if (!campaign) missingData.push("campaign");
          if (!agent) missingData.push("agent");

          const errorMessage = `No pudimos obtener la información de: ${missingData.join(", ")}`;
          console.error(errorMessage);
          AlertService.toastError(
            "¡ Atención !, No pudimos cargar la información, contacta al administrador.",
          );
          return;
        }

        if (response && response.customer) {
          const { phone_number, data, ...customerInfo } = response.customer;
          const filteredData = data.filter(
            (row: any) => row.show_agent === true,
          );
          const updatedRows = filteredData.map((row: any) => ({
            ...row,
            customer: {
              ...customerInfo,
            },
          }));

          const uniqueLabels = new Set();

          const mappedTipifications = response.campaign.typifications.reduce(
            (acc: any[], item: any) => {
              const label = item.label;
              if (!uniqueLabels.has(label)) {
                uniqueLabels.add(label);
                acc.push({
                  value: label,
                  label: label,
                  children:
                    item.children?.map((child: any) => ({
                      value: child.label,
                      label: child.label,
                      children:
                        child.children?.map((grandchild: any) => ({
                          value: grandchild.label,
                          label: grandchild.label,
                          children: grandchild.children || [],
                        })) || [],
                    })) || [],
                });
              }
              return acc;
            },
            [],
          );

          const forms = campaign.product.forms;

          const legends = forms.reduce((acc: any, form: any) => {
            acc[form.legend] = [];
            return acc;
          }, {});

          setCreatedForms((prevState) => ({
            ...prevState,
            ...legends,
          }));

          setFolio(
            campaign.product.code === "GA"
              ? campaign.product.code +
                  "TMK-" +
                  dateHourFormatted +
                  agent.username +
                  "-"
              : campaign.product.code +
                  "477-" +
                  dateHourFormatted +
                  agent.username +
                  "-",
          );

          setIdCampaigns(campaign._id);
          setTipifications(mappedTipifications);
          setNumber(phone_number);
          setRows(updatedRows);
          setNameAgent(response.agent.name);
          setUserAgent(response.agent.username);
          setIdAgent(response.agent._id);
          setValue("agent", response.agent._id);
          setValue("campaign", campaign._id);
          setDataBase(campaign.product.forms);
          setDataRules(data);
          AlertService.toastSuccess(
            "¡ Éxito !, información cargada correctamente.",
          );
        }
      } catch (error) {
        console.error("Error al obtener la información:", error);
        AlertService.toastError(
          "¡ Atención !, No pudimos cargar la información, contacta al administrador.",
        );
      }
    } else {
      console.error("No se obtuvo customer_id y/o ucontact_id.");
      setTimeout(() => {
        AlertService.toastError(
          "¡ Atención !, No pudimos cargar la información, contacta al administrador.",
        );
      }, 300);
    }
  };

  const submit = async (data: any) => {
    const confirmed = await AlertService.question(
      "¿Deseas guardar formulario con estas tipificaciones seleccionadas? ",
      <div className="text-nowrap">
        <h4 className="text-primary fw-bolder mb-4">Tipificaciones</h4>
        <h5>
          Nivel 1: <span className="text-muted">{data.level_1}</span>
        </h5>
        <h5>
          Nivel 2: <span className="text-muted">{data.level_2}</span>
        </h5>
        <h5>
          Nivel 3: <span className="text-muted">{data.level_3}</span>
        </h5>
      </div>,
      () => Promise.resolve(),
      "Si, guardar",
    );

    if (!confirmed) return;

    try {
      if (actionsV !== "SALE") {
        const dataSend = {
          campaign: idCampaigns,
          register: customer_id,
          agent: idAgent,
          level_1: data.level_1,
          level_2: data.level_2,
          level_3: data.level_3,
          action: data.action,
          time_to_reschedule: data.time_to_reschedule,
          comments: data.comments,
        };
        await AgentsService.saveForm(dataSend);
      } else {
        const formsToSubmit: any[] = [];
        const result: { [key: string]: any[] } = {};
        data.forms.forEach((forms: any[]) => {
          const keys: string[] = Array.from(
            new Set(forms.flatMap(Object.keys)),
          );
          keys.forEach((key) => {
            if (!result[key]) {
              result[key] = [];
            }
            const values = forms.map((item: any) => {
              return item[key] !== null ? item[key] : "";
            });
            result[key] = result[key].concat(values);
          });
        });
        for (const key in result) {
          const values = result[key];
          for (const legend in createdForms) {
            const formEntries = createdForms[legend];
            const foundForm = formEntries.find((form) => form.id === key);
            if (foundForm) {
              const controlsData =
                dataBase
                  .find((item) => item.legend === legend)
                  ?.controls.map((control) => {
                    return {
                      ...control,
                      value: values.shift() || "",
                    };
                  }) || [];
              formsToSubmit.push({
                legend,
                controls: controlsData,
              });
            }
          }
        }

        const dataSend = {
          campaign: idCampaigns,
          register: customer_id,
          agent: idAgent,
          level_1: "Venta exitosa",
          level_2: "",
          level_3: "",
          action: "SALE",
          time_to_reschedule: "",
          comments: data.comments,
          forms: formsToSubmit,
        };

        await AgentsService.saveForm(dataSend);
        setQuestionSuccess(false);
      }

      getCustomer();
      AlertService.toastSuccess(
        `¡ Éxito !, Se guardó el formulario correctamente.`,
      );
      const message = {
        value1: data.level_1 || "",
        value2: data.level_2 || "",
        value3: data.level_3 || "",
        action: data.action || "",
        timeToReschedule: data.time_to_reschedule || "",
      };
      const messageString = JSON.stringify(message);
      window.parent.postMessage(JSON.stringify(message), "*");
      setPostMessageData(messageString);
      reset(defaultValues);
      setStatusInputs([]);
    } catch (error) {
      console.error("Error al guardar el formulario: ", error);
      AlertService.toastWarning(
        `¡ Atención !, No pudimos guardar el formulario, contacta al administrador.`,
      );
    }
  };

  useEffect(() => {
    getCustomer();
  }, [customer_id, ucontact_id, token]);

  useEffect(() => {
    if (Object.values(createdForms).every((form) => form.length === 0)) {
      reset((prevValues) => {
        return {
          ...prevValues,
          forms: [],
        };
      });
    }
  }, [createdForms, reset]);

  return (
    <>
      <div className="page d-flex flex-row flex-column-fluid me-16">
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <Header userAgent={userAgent} nameAgent={nameAgent} />
          <div
            className="content fs-7 d-flex flex-column flex-column-fluid"
            id="kt_content"
          >
            <div className="post fs-7 d-flex flex-column-fluid" id="kt_post">
              <div className="container-xxl">
                <div className="card mb-10">
                  <div className="card-body p-5 px-lg-19 py-lg-16">
                    <div className="mb-14">
                      <div className="card-toolbar mb-9">
                        <div className="d-flex flex-stack flex-wrap gap-4">
                          <div className="d-flex align-items-center fw-bold">
                            <div className="">
                              <h4 className="fs-1 text-gray-900">
                                Información de cuenta
                              </h4>
                              <span className="text-gray-500">
                                Esta información es{" "}
                                <span className="text-danger fw-bold">
                                  confidencial
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center text-center">
                            <div>
                              <a className="text-gray-800 fw-bold text-hover-primary fs-4">
                                {maskNumber(number)}
                              </a>
                              <div>
                                <span className="w-250px fw-bold text-muted">
                                  Número cliente
                                </span>
                              </div>
                            </div>
                            <div className="symbol symbol-80px symbol-lg-150px">
                              <i className="ki-duotone ki-profile-circle fs-6x text-primary">
                                <span className="path1" />
                                <span className="path2" />
                                <span className="path3" />
                              </i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-14">
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                              <tr className="text-gray-800 text-left fw-bold fs-6 border-0 bg-light-info ">
                                <th className="min-w-140px rounded-start p-3">
                                  Campo
                                </th>
                                <th className="p-3 min-w-140px">Valor</th>
                              </tr>
                            </thead>
                            <tbody className="border-bottom border-dashed">
                              {rows.map((row: any, index: number) => (
                                <tr key={index}>
                                  <td>
                                    <div className="text-nowrap">
                                      <h5 className="mb-0">
                                        <strong>{row.meaning}</strong>
                                      </h5>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="text-nowrap">
                                      <h5 className="mb-0 text-info">
                                        <strong>{row.value}</strong>
                                      </h5>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body p-5 px-lg-19 py-lg-16">
                    <div className="mb-14">
                      <div className="mt-1 text-center">
                        <h1>Formulario de llamada</h1>
                        <div className="text-muted fw-semibold fs-5">
                          Los campos marcados con <span className="required" />{" "}
                          son{" "}
                          <a className="link-danger fw-bold ms-2">
                            Obligatorios
                          </a>
                          .
                        </div>
                      </div>
                      <div className="separator border-2 my-5 mb-10"></div>
                      <form onSubmit={handleSubmit(submit)}>
                        <label className="d-flex flex-stack mb-7">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-circle symbol-50px me-6">
                              <span className="symbol-label bg-light-info">
                                <i className="ki-duotone ki-share fs-3x text-info">
                                  <span className="path1" />
                                  <span className="path2" />
                                  <span className="path2" />
                                  <span className="path3" />
                                  <span className="path4" />
                                  <span className="path5" />
                                  <span className="path6" />
                                </i>
                              </span>
                            </span>
                            <span className="d-flex flex-column">
                              <h2 className="fw-bold">
                                Tpificaciones y acciones
                                <span className="required"></span>
                              </h2>
                              <div className="text-muted fw-semibold fs-6">
                                Selecciona alguna titpificación y realiza un
                                acción
                              </div>
                            </span>
                          </span>
                        </label>
                        <div className="row">
                          <div className="col-6">
                            <div className="fv-row mb-7">
                              <div className="d-flex justify-content-between align-items-center">
                                <label className="form-label required">
                                  Tipificaciones
                                </label>
                                <span className="fs-6 text-info fw-bolder">
                                  1° nivel
                                </span>
                              </div>
                              <Controller
                                control={control}
                                name="level_1"
                                rules={{ required: true }}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <>
                                    <Select
                                      options={tipifications}
                                      isMulti={false}
                                      noOptionsMessage={() => (
                                        <div className="empty-select">
                                          {"No hay opciones disponibles"}
                                        </div>
                                      )}
                                      className={
                                        error
                                          ? "form-select-custom is-invalid"
                                          : "form-select-custom"
                                      }
                                      classNamePrefix="form-select-custom"
                                      placeholder={
                                        "Selecciona una tipificación"
                                      }
                                      onChange={(selectedOption) => {
                                        onChange(
                                          selectedOption
                                            ? selectedOption.value
                                            : null,
                                        );
                                        setValue("level_2", "");
                                        setValue("level_3", "");
                                      }}
                                      value={
                                        tipifications.find(
                                          (option) => option.value === value,
                                        ) || null
                                      }
                                    />
                                    <InvalidFeedback error={error} />
                                  </>
                                )}
                              />
                            </div>

                            {level2 && hasChildrenLevel2 && (
                              <>
                                <div className="fv-row mb-7">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <label className="form-label">
                                      Tipificaciones
                                      <span className="fs-8 text-muted ms-1">
                                        (Opcional)
                                      </span>
                                    </label>
                                    <span className="fs-6 text-info fw-bolder">
                                      2° nivel
                                    </span>
                                  </div>
                                  <Controller
                                    control={control}
                                    name="level_2"
                                    rules={{ required: false }}
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <>
                                        <Select
                                          options={optionsLevel2}
                                          isMulti={false}
                                          isClearable
                                          noOptionsMessage={() => (
                                            <div className="empty-select">
                                              {"No hay opciones disponibles"}
                                            </div>
                                          )}
                                          className={
                                            error
                                              ? "form-select-custom is-invalid"
                                              : "form-select-custom"
                                          }
                                          classNamePrefix="form-select-custom"
                                          placeholder={
                                            "Selecciona tipificación"
                                          }
                                          onChange={(selectedOption) => {
                                            onChange(
                                              selectedOption
                                                ? selectedOption.value
                                                : "",
                                            );
                                            setValue("level_3", "");
                                          }}
                                          value={
                                            optionsLevel2.find(
                                              (optionsLevel2: any) =>
                                                optionsLevel2.label === value,
                                            ) || null
                                          }
                                        />
                                        <InvalidFeedback error={error} />
                                      </>
                                    )}
                                  />
                                </div>
                              </>
                            )}

                            {level3 && hasChildrenLevel3 && (
                              <>
                                <div className="fv-row mb-7">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <label className="form-label">
                                      Tipificaciones
                                      <span className="fs-8 text-muted ms-1">
                                        (Opcional)
                                      </span>
                                    </label>
                                    <span className="fs-6 text-info fw-bolder">
                                      3° nivel
                                    </span>
                                  </div>
                                  <Controller
                                    control={control}
                                    name="level_3"
                                    rules={{ required: false }}
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <>
                                        <Select
                                          options={optionsLevel3}
                                          isMulti={false}
                                          isClearable
                                          noOptionsMessage={() => (
                                            <div className="empty-select">
                                              {"No hay opciones disponibles"}
                                            </div>
                                          )}
                                          className={
                                            error
                                              ? "form-select-custom is-invalid"
                                              : "form-select-custom"
                                          }
                                          classNamePrefix="form-select-custom"
                                          placeholder={
                                            "Selecciona tipificación"
                                          }
                                          onChange={(selectedOption) => {
                                            onChange(
                                              selectedOption
                                                ? selectedOption.value
                                                : "",
                                            );
                                          }}
                                          value={
                                            optionsLevel3.find(
                                              (optionsLevel3: any) =>
                                                optionsLevel3.label === value,
                                            ) || null
                                          }
                                        />
                                        <InvalidFeedback error={error} />
                                      </>
                                    )}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                          <div className="col-6">
                            <div className="fv-row mb-4">
                              <label className="form-label required">
                                Acciones
                                <span className="fs-8 text-muted ms-1" />
                              </label>
                              <Controller
                                control={control}
                                name="action"
                                rules={{ required: true }}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <>
                                    <Select
                                      options={actions}
                                      isMulti={false}
                                      noOptionsMessage={() => (
                                        <div className="empty-select">
                                          {"No hay opciones disponibles"}
                                        </div>
                                      )}
                                      className={
                                        error
                                          ? "form-select-custom is-invalid"
                                          : "form-select-custom"
                                      }
                                      classNamePrefix="form-select-custom"
                                      placeholder={"Selecciona una acción"}
                                      onChange={(selectedOption) => {
                                        handleActionChange(selectedOption);
                                        setQuestionSuccess(false);
                                        setCreatedForms((prevState) => {
                                          const resetForms = Object.keys(
                                            prevState,
                                          ).reduce((acc: any, key: any) => {
                                            acc[key] = [];
                                            return acc;
                                          }, {});
                                          return resetForms;
                                        });
                                        setStatusInputs([]);
                                        onChange(
                                          selectedOption
                                            ? selectedOption.value
                                            : null,
                                        );
                                      }}
                                      value={
                                        actions.filter((option: any) =>
                                          value?.includes(option.value),
                                        ) || []
                                      }
                                    />
                                    <InvalidFeedback error={error} />
                                  </>
                                )}
                              />
                            </div>

                            {watch("action") === "RESCHEDULE" && (
                              <Controller
                                control={control}
                                name="time_to_reschedule"
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <DateHour
                                    label={"Agenda"}
                                    requiredIndicator="required"
                                    errors={errors.time_to_reschedule}
                                    placeholder={"Selecciona día y hora"}
                                    value={field.value}
                                    onChange={field.onChange}
                                  />
                                )}
                              />
                            )}
                          </div>
                        </div>

                        {questionSuccess && (
                          <>
                            <label className="d-flex flex-stack mb-10 mt-10">
                              <span className="d-flex align-items-center me-2">
                                <span className="symbol symbol-circle symbol-50px me-6">
                                  <span className="symbol-label bg-light-danger">
                                    <i className="ki-duotone ki-text-circle fs-3x text-danger">
                                      <span className="path1" />
                                      <span className="path2" />
                                      <span className="path3" />
                                      <span className="path4" />
                                      <span className="path5" />
                                      <span className="path6" />
                                    </i>
                                  </span>
                                </span>
                                <span className="d-flex flex-column">
                                  <h2 className="fw-bold">
                                    Formularios de venta
                                    <span className="fs-8 text-muted ms-1">
                                      (Opcional)
                                    </span>
                                  </h2>
                                  <div className="text-muted fw-semibold fs-6">
                                    Al menos debes registrar un formulario.
                                  </div>
                                </span>
                              </span>
                            </label>
                            <div className="">
                              <div className="notice d-flex bg-light-warning rounded border-primary border border-dashed mb-15 p-6 mw-700px ms-20">
                                <i className="ki-duotone ki-design-1 fs-2tx text-primary me-4" />
                                <div className="d-flex flex-stack flex-grow-1 ">
                                  <div className=" fw-semibold">
                                    <div className="fs-6 text-gray-700 ">
                                      <strong className="fw-bolder text-warning">
                                        ¡ Atención !,{" "}
                                      </strong>
                                      Si la venta no se concreta, es{" "}
                                      <strong className="fw-bolder text-danger">
                                        necesario eliminar
                                      </strong>{" "}
                                      los formularios creados antes de
                                      continuar. Esto garantizará que el proceso
                                      se complete correctamente.
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {dataBase.map((item, itemIndex) => (
                                <div key={itemIndex}>
                                  <div className="mw-375px">
                                    <div className="d-flex justify-content-between align-items-center mb-10 ms-20">
                                      <div className="d-flex flex-column flex-grow-1">
                                        <a className="fs-5 text-gray-800 text-hover-primary fw-bolder">
                                          {item.legend}
                                        </a>
                                        <div className="fs-7 text-gray-400 fw-semibold mt-1">
                                          {item.legend === "Transferencia DDA"
                                            ? "No hay límite de formularios"
                                            : `Máximo hasta ${item.max} formularios`}
                                        </div>
                                      </div>
                                      <a
                                        type="button"
                                        className="btn btn-light-info btn-sm"
                                        onClick={() =>
                                          handleCreateForm(item.legend)
                                        }
                                      >
                                        <i className="ki-duotone ki-abstract-10 fs-2x">
                                          <span className="path1" />
                                          <span className="path2" />
                                        </i>
                                      </a>
                                    </div>
                                  </div>

                                  {/* Creacion de formularios */}
                                  <div className="ms-20 mb-15">
                                    {createdForms[item.legend]?.map(
                                      (form, index) => (
                                        <div
                                          className="accordion accordion-icon-toggle mb-7"
                                          id={`accordion_${itemIndex}_${index}_${form.id}`}
                                          key={index}
                                        >
                                          <div
                                            className="accordion-header"
                                            data-bs-target={`#accordion_${itemIndex}_${index}_${form.id}_item`}
                                            data-bs-toggle="collapse"
                                          >
                                            <div className="mw-375px">
                                              <div className="d-flex justify-content-between align-items-center mb-0">
                                                <div className="d-flex align-items-center flex-grow-1">
                                                  <span className="accordion-icon">
                                                    <i className="ki-duotone ki-arrow-right fs-1">
                                                      <span className="path1" />
                                                      <span className="path2" />
                                                    </i>
                                                  </span>
                                                  <a className="fs-5 text-primary fw-bolder ms-3">
                                                    {item.legend}
                                                  </a>
                                                </div>
                                                <a
                                                  type="button"
                                                  className="btn btn-light-danger btn-sm me-20"
                                                  onClick={() =>
                                                    handleRemoveGroup(
                                                      item.legend,
                                                      index,
                                                      item.controls,
                                                    )
                                                  }
                                                >
                                                  <i className="ki-duotone ki-trash fs-2x">
                                                    <span className="path1" />
                                                    <span className="path2" />
                                                    <span className="path3" />
                                                    <span className="path4" />
                                                    <span className="path5" />
                                                  </i>
                                                </a>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            id={`accordion_${itemIndex}_${index}_${form.id}_item`}
                                            className="accordion-body collapse show"
                                            data-bs-parent={`#accordion_${itemIndex}_${index}_${form.id}`}
                                          >
                                            <div className="accordion-body border rounded d-flex flex-wrap">
                                              {item.controls.map(
                                                (controls, ctrlIndex) => (
                                                  <div
                                                    key={ctrlIndex}
                                                    className="two-column-input"
                                                  >
                                                    <>
                                                      {/* Primer bloque: Componentes que NO contienen show_when */}
                                                      {!controls.show_when && (
                                                        <>
                                                          {controls.type ===
                                                          "select" ? (
                                                            <div className="fv-row mb-0">
                                                              <label className="form-label required">
                                                                {controls.label}
                                                              </label>
                                                              <Controller
                                                                control={
                                                                  control
                                                                }
                                                                name={
                                                                  `forms.${index}.${ctrlIndex}.${form.id}` as keyof MyFieldErrors
                                                                }
                                                                rules={{
                                                                  required:
                                                                    controls.is_mandatory,
                                                                }}
                                                                render={({
                                                                  field: {
                                                                    onChange,
                                                                    value,
                                                                  },
                                                                  fieldState: {
                                                                    error,
                                                                  },
                                                                }) => (
                                                                  <>
                                                                    <Select
                                                                      options={
                                                                        controls.options?.map(
                                                                          (
                                                                            option,
                                                                          ) => ({
                                                                            value:
                                                                              option,
                                                                            label:
                                                                              option,
                                                                          }),
                                                                        ) || []
                                                                      }
                                                                      isClearable={
                                                                        !controls.is_mandatory
                                                                      }
                                                                      noOptionsMessage={() => (
                                                                        <div className="empty-select">
                                                                          No hay
                                                                          opciones
                                                                          disponibles
                                                                        </div>
                                                                      )}
                                                                      className={`form-select-custom ${error ? "is-invalid" : ""}`}
                                                                      classNamePrefix="form-select-custom"
                                                                      placeholder={`Selecciona ${controls.label}`}
                                                                      onChange={(
                                                                        selectedOption,
                                                                      ) => {
                                                                        const selectedValue =
                                                                          selectedOption
                                                                            ? (
                                                                                selectedOption as {
                                                                                  value: string;
                                                                                  label: string;
                                                                                }
                                                                              )
                                                                                .value
                                                                            : "";
                                                                        onChange(
                                                                          selectedValue,
                                                                        );
                                                                        handleSelectChange(
                                                                          selectedValue,
                                                                          controls.group,
                                                                        );
                                                                      }}
                                                                      value={
                                                                        controls.options
                                                                          ?.map(
                                                                            (
                                                                              option,
                                                                            ) => ({
                                                                              value:
                                                                                option,
                                                                              label:
                                                                                option,
                                                                            }),
                                                                          )
                                                                          .find(
                                                                            (
                                                                              option,
                                                                            ) =>
                                                                              option.value ===
                                                                              value,
                                                                          ) ||
                                                                        ""
                                                                      }
                                                                    />
                                                                    <InvalidFeedback
                                                                      error={
                                                                        error
                                                                      }
                                                                    />
                                                                  </>
                                                                )}
                                                              />
                                                            </div>
                                                          ) : (
                                                            <Input
                                                              label={
                                                                controls.label
                                                              }
                                                              requiredIndicator={
                                                                controls.is_mandatory
                                                                  ? "required"
                                                                  : ""
                                                              }
                                                              optional={
                                                                !controls.is_mandatory
                                                              }
                                                              placeholder={`Ingresa ${controls.label}`}
                                                              {...register(
                                                                `forms.${index}.${ctrlIndex}.${form.id}` as keyof MyFieldErrors,
                                                                {
                                                                  required:
                                                                    controls.is_mandatory,
                                                                  ...(controls.max_length && {
                                                                    maxLength: {
                                                                      value:
                                                                        controls.max_length,
                                                                      message: `${controls.max_length}`,
                                                                    },
                                                                  }),
                                                                  value:
                                                                    controls.type ===
                                                                    "folio"
                                                                      ? `${folio}${Math.floor(100000 + Math.random() * 900000)}`
                                                                      : "",
                                                                  pattern: {
                                                                    value:
                                                                      getPatternForType(
                                                                        controls.type,
                                                                      ),
                                                                    message:
                                                                      controls.type ===
                                                                      "curp"
                                                                        ? curpError ||
                                                                          "El CURP no tiene un formato válido, deben ser 18 caracteres XXXX820302HPLNLR09 y debe ser igual o mayor a 18 años de edad"
                                                                        : controls.type ===
                                                                            "fecha_nacimiento"
                                                                          ? dateError ||
                                                                            "La fecha de nacimiento no tiene un formato válido dd/mm/yyyy y debe ser igual o mayor a 18 años de edad."
                                                                          : getMessageForType(
                                                                              controls.type,
                                                                            ),
                                                                  },
                                                                  validate: {
                                                                    isAdult: (
                                                                      value,
                                                                    ) => {
                                                                      if (
                                                                        controls.type ===
                                                                          "curp" &&
                                                                        value
                                                                      ) {
                                                                        const result =
                                                                          validateCurp(
                                                                            value,
                                                                          );
                                                                        if (
                                                                          !result.valid
                                                                        ) {
                                                                          setCurpError(
                                                                            result.message,
                                                                          );
                                                                          return result.message;
                                                                        }
                                                                      }

                                                                      if (
                                                                        controls.type ===
                                                                          "fecha_nacimiento" &&
                                                                        value
                                                                      ) {
                                                                        const result =
                                                                          validateDateOfBirth(
                                                                            value,
                                                                          );
                                                                        if (
                                                                          !result.valid
                                                                        ) {
                                                                          setDateError(
                                                                            result.message,
                                                                          );
                                                                          return dateError;
                                                                        }
                                                                      }

                                                                      return true;
                                                                    },
                                                                  },
                                                                },
                                                              )}
                                                              disabled={
                                                                controls.type ===
                                                                "folio"
                                                              }
                                                              onInput={(
                                                                e: any,
                                                              ) => {
                                                                let handleInput;

                                                                if (
                                                                  controls.type ===
                                                                    "no_exterior" ||
                                                                  controls.type ===
                                                                    "no_interior"
                                                                ) {
                                                                  handleInput =
                                                                    handleSpecialCharacters;
                                                                } else if (
                                                                  controls.label.toLowerCase() ===
                                                                    "colonia" ||
                                                                  controls.label.toLowerCase() ===
                                                                    "municipio" ||
                                                                  controls.label.toLowerCase() ===
                                                                    "estado" ||
                                                                  controls.label.toLowerCase() ===
                                                                    "calle" ||
                                                                  controls.type ===
                                                                    "curp"
                                                                ) {
                                                                  handleInput =
                                                                    handleSpecialCharactersDoubleSpaces;
                                                                } else {
                                                                  handleInput =
                                                                    handleNoDoubleSpaces;
                                                                }
                                                                if (
                                                                  handleInput
                                                                ) {
                                                                  handleInput(
                                                                    e,
                                                                  );
                                                                }
                                                              }}
                                                              errors={
                                                                errors?.forms?.[
                                                                  index
                                                                ]?.[
                                                                  ctrlIndex
                                                                ]?.[form.id] ||
                                                                null
                                                              }
                                                            />
                                                          )}
                                                        </>
                                                      )}

                                                      {/* Segundo bloque: Componentes que SÍ contienen show_when */}
                                                      {controls.show_when &&
                                                        shouldShowInput(
                                                          controls,
                                                        ) && (
                                                          <>
                                                            {controls.type ===
                                                            "select" ? (
                                                              <div className="fv-row mb-0">
                                                                <label className="form-label required">
                                                                  {
                                                                    controls.label
                                                                  }
                                                                </label>
                                                                <Controller
                                                                  control={
                                                                    control
                                                                  }
                                                                  name={
                                                                    `forms.${index}.${ctrlIndex}.${form.id}` as keyof MyFieldErrors
                                                                  }
                                                                  rules={{
                                                                    required:
                                                                      controls.is_mandatory,
                                                                  }}
                                                                  render={({
                                                                    field: {
                                                                      onChange,
                                                                      value,
                                                                    },
                                                                    fieldState:
                                                                      { error },
                                                                  }) => (
                                                                    <>
                                                                      <Select
                                                                        options={
                                                                          controls.options?.map(
                                                                            (
                                                                              option,
                                                                            ) => ({
                                                                              value:
                                                                                option,
                                                                              label:
                                                                                option,
                                                                            }),
                                                                          ) ||
                                                                          []
                                                                        }
                                                                        isClearable={
                                                                          !controls.is_mandatory
                                                                        }
                                                                        noOptionsMessage={() => (
                                                                          <div className="empty-select">
                                                                            No
                                                                            hay
                                                                            opciones
                                                                            disponibles
                                                                          </div>
                                                                        )}
                                                                        className={`form-select-custom ${error ? "is-invalid" : ""}`}
                                                                        classNamePrefix="form-select-custom"
                                                                        placeholder={`Selecciona ${controls.label}`}
                                                                        onChange={(
                                                                          selectedOption,
                                                                        ) =>
                                                                          onChange(
                                                                            selectedOption
                                                                              ? (
                                                                                  selectedOption as {
                                                                                    value: string;
                                                                                    label: string;
                                                                                  }
                                                                                )
                                                                                  .value
                                                                              : "",
                                                                          )
                                                                        }
                                                                        value={
                                                                          controls.options
                                                                            ?.map(
                                                                              (
                                                                                option,
                                                                              ) => ({
                                                                                value:
                                                                                  option,
                                                                                label:
                                                                                  option,
                                                                              }),
                                                                            )
                                                                            .find(
                                                                              (
                                                                                option,
                                                                              ) =>
                                                                                option.value ===
                                                                                value,
                                                                            ) ||
                                                                          ""
                                                                        }
                                                                      />
                                                                      <InvalidFeedback
                                                                        error={
                                                                          error
                                                                        }
                                                                      />
                                                                    </>
                                                                  )}
                                                                />
                                                              </div>
                                                            ) : (
                                                              <Input
                                                                label={
                                                                  controls.label
                                                                }
                                                                requiredIndicator={
                                                                  controls.is_mandatory
                                                                    ? "required"
                                                                    : ""
                                                                }
                                                                optional={
                                                                  !controls.is_mandatory
                                                                }
                                                                placeholder={`Ingresa ${controls.label}`}
                                                                {...register(
                                                                  `forms.${index}.${ctrlIndex}.${form.id}` as keyof MyFieldErrors,
                                                                  {
                                                                    required:
                                                                      controls.is_mandatory,
                                                                    ...(controls.max_length && {
                                                                      maxLength:
                                                                        {
                                                                          value:
                                                                            controls.max_length,
                                                                          message: `${controls.max_length}`,
                                                                        },
                                                                    }),
                                                                    value:
                                                                      controls.type ===
                                                                      "folio"
                                                                        ? `${folio}${Math.floor(100000 + Math.random() * 900000)}`
                                                                        : "",
                                                                    pattern: {
                                                                      value:
                                                                        getPatternForType(
                                                                          controls.type,
                                                                        ),
                                                                      message:
                                                                        controls.type ===
                                                                        "curp"
                                                                          ? curpError ||
                                                                            "El CURP no tiene un formato válido, deben ser 18 caracteres XXXX820302HPLNLR09 y  debe ser igual o mayor a 18 años de edad"
                                                                          : controls.type ===
                                                                              "fecha_nacimiento"
                                                                            ? dateError ||
                                                                              "La fecha de nacimiento no tiene un formato válido dd/mm/yyyy debe ser igual o mayor a 18 años de edad"
                                                                            : getMessageForType(
                                                                                controls.type,
                                                                              ),
                                                                    },
                                                                    validate: {
                                                                      isAdult: (
                                                                        value,
                                                                      ) => {
                                                                        if (
                                                                          controls.type ===
                                                                            "curp" &&
                                                                          value
                                                                        ) {
                                                                          const result =
                                                                            validateCurp(
                                                                              value,
                                                                            );
                                                                          if (
                                                                            !result.valid
                                                                          ) {
                                                                            setCurpError(
                                                                              result.message,
                                                                            );
                                                                            return result.message;
                                                                          }
                                                                        }

                                                                        if (
                                                                          controls.type ===
                                                                            "fecha_nacimiento" &&
                                                                          value
                                                                        ) {
                                                                          const result =
                                                                            validateDateOfBirth(
                                                                              value,
                                                                            );
                                                                          if (
                                                                            !result.valid
                                                                          ) {
                                                                            setDateError(
                                                                              result.message,
                                                                            );
                                                                            return dateError;
                                                                          }
                                                                        }

                                                                        return true;
                                                                      },
                                                                    },
                                                                  },
                                                                )}
                                                                disabled={
                                                                  controls.type ===
                                                                  "folio"
                                                                }
                                                                onInput={(
                                                                  e: any,
                                                                ) => {
                                                                  let handleInput;

                                                                  // if (
                                                                  //   controls.type ===
                                                                  //     "no_exterior" ||
                                                                  //   controls.type ===
                                                                  //     "no_interior"
                                                                  // ) {
                                                                  //   handleInput =
                                                                  //     handleSpecialCharacters;
                                                                  // } else
                                                                  if (
                                                                    controls.label.toLowerCase() ===
                                                                      "colonia" ||
                                                                    controls.label.toLowerCase() ===
                                                                      "municipio" ||
                                                                    controls.label.toLowerCase() ===
                                                                      "estado" ||
                                                                    controls.label.toLowerCase() ===
                                                                      "calle" ||
                                                                    controls.type ===
                                                                      "curp"
                                                                  ) {
                                                                    handleInput =
                                                                      handleSpecialCharactersDoubleSpaces;
                                                                  } else {
                                                                    handleInput =
                                                                      handleNoDoubleSpaces;
                                                                  }
                                                                  if (
                                                                    handleInput
                                                                  ) {
                                                                    handleInput(
                                                                      e,
                                                                    );
                                                                  }
                                                                }}
                                                                errors={
                                                                  errors
                                                                    ?.forms?.[
                                                                    index
                                                                  ]?.[
                                                                    ctrlIndex
                                                                  ]?.[
                                                                    form.id
                                                                  ] || null
                                                                }
                                                              />
                                                            )}
                                                          </>
                                                        )}
                                                    </>
                                                  </div>
                                                ),
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      ),
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </>
                        )}

                        <label className="d-flex flex-stack mb-7 mt-20">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-circle symbol-50px me-6">
                              <span className="symbol-label bg-light-primary">
                                <i className="ki-duotone ki-tablet-text-down fs-3x text-primary">
                                  <span className="path1" />
                                  <span className="path2" />
                                  <span className="path3" />
                                  <span className="path4" />
                                </i>
                              </span>
                            </span>
                            <span className="d-flex flex-column">
                              <h2 className="fw-bold">
                                Observaciones
                                <span className="required"></span>
                              </h2>
                              <div className="text-muted fw-semibold fs-6">
                                Escribe un comentario.
                              </div>
                            </span>
                          </span>
                        </label>

                        <div className="fv-row mb-7">
                          <TextArea
                            label="Comentarios"
                            requiredIndicator="required"
                            placeholder="Ingresa un comentario"
                            errors={errors.comments}
                            {...register("comments", {
                              required: true,
                              setValueAs: (value) => value.trim(),
                            })}
                          />
                        </div>

                        <div className="fv-row mb-7">
                          <label>PostMessage</label>
                          <pre>{postMessageData}</pre>
                        </div>

                        <div className="d-flex justify-content-center">
                          <Button
                            type="submit"
                            id="sendData"
                            className="btn btn-lg btn-primary ms-3 hover-scale"
                            disabled={!isValid || isSubmitting}
                            isLoading={isSubmitting}
                            onClick={handleSubmit(submit)}
                          >
                            <i className="ki-duotone ki-send me-2 fs-1">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                            Guardar formulario
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        <SecurityQuestion
          show={showModal.show}
          onRequestClose={(refresh: any, question: any, pm: any) => {
            handleShowModal(refresh, question);
            if (question) {
              setQuestionSuccess(true);
            }
            setPostMessageData(pm);
          }}
          payload={showModal.data}
          agent={idAgent}
          registerID={customer_id ?? ""}
          campaign={idCampaigns ?? ""}
        />
      </div>
    </>
  );
};

export default OpenRoutePage;
